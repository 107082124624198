import { styled } from '@Estoca/ui';

export const StyledProfile = styled('div', {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '20px',

  display: 'flex',
  alignItems: 'center',
  color: '#8F9BB3',
  span: {
    width: 'max-content',
  },
  borderRight: 'none !important',

  '@small': {
    width: '38%',
  },
});
