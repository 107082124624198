/* eslint-disable react/jsx-no-constructed-context-values */
import {
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from 'react';
import { createContext } from 'use-context-selector';

type Group = {
  id: string;
  name: string;
};

export type Store = {
  id: string;
  name: string;
  order: number;
  is_test: boolean;
  is_churn: boolean;
  is_saas: boolean;
  legal_name?: string;
};

type Warehouses = {
  id: string;
  name: string;
};

export type ProfileData = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  groups: Group[];
  stores?: Store[];
  warehouses?: Warehouses[];
  storeSelected?: Store;
};

interface ProfileDataContextData {
  profileData: ProfileData | null;
  setProfileData: Dispatch<SetStateAction<ProfileData | null>>;
}

export const ProfileDataContext = createContext({} as ProfileDataContextData);

export const ProfileDataContextProvider = ({
  profileData: initialProfileData,
  children,
}: {
  profileData: ProfileData | null;
  children: ReactNode;
}) => {
  const [profileData, setProfileData] = useState<ProfileData | null>(
    initialProfileData || null
  );

  return (
    <ProfileDataContext.Provider
      value={{
        profileData,
        setProfileData,
      }}
    >
      {children}
    </ProfileDataContext.Provider>
  );
};
