import { useContextSelector } from 'use-context-selector';

import { ProfileDataContext } from '../../../context/ProfileDataContext';
import { DropdownHeader } from '../DropdownHeader';

import { StyledProfile } from './styles';

export type ProfileMenuItem = {
  name: string;
  href: string;
  isExternalLink?: boolean;
};

const IDEstocaURL = process.env.REACT_APP_LOGIN_URL;

export const profileMenuItems: ProfileMenuItem[] = [
  {
    name: 'Sair',
    href: `${IDEstocaURL}/logout`,
  },
];

export const Profile = () => {
  const profileData = useContextSelector(
    ProfileDataContext,
    ctx => ctx.profileData
  );

  return (
    <StyledProfile>
      <DropdownHeader items={profileMenuItems} />
      <span>{`${profileData?.first_name} ${profileData?.last_name}`}</span>
    </StyledProfile>
  );
};
