import { type Dispatch, type SetStateAction } from 'react';

import { StyledBurger } from './styles';

interface MobileBurgerProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const MobileBurger = ({ isOpen, setIsOpen }: MobileBurgerProps) => {
  const toggleOpenMenu = () => {
    setIsOpen(isOpen => {
      document.body.style.overflow = !isOpen ? 'hidden' : 'visible';
      return !isOpen;
    });
  };

  return (
    <StyledBurger open={isOpen} onClick={toggleOpenMenu}>
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};
