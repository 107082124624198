import { styled } from '@Estoca/ui';

export const Wrapper = styled('div', {
  display: 'none',

  '@small': {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      border: '1px solid #edf1f7',
      borderRight: 'none',
    },
  },
});
