import { type Dispatch, type SetStateAction } from 'react';

import estocaLogo from '../../../public/estoca-logo.svg';
import Email from '../Email';
import { Profile } from '../Profile';
import { SearchOrder } from '../SearchOrder';
import { Store } from '../Store';

import * as S from './styles';

interface HeaderInformationProps {
  openSearch: boolean;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  setOpenStoreModal: Dispatch<SetStateAction<boolean>>;
}

export const HeaderInformation = ({
  openSearch,
  setOpenSearch,
  setOpenStoreModal,
}: HeaderInformationProps) => {
  return (
    <S.Wrapper>
      <a href="/">
        <S.Logo
          src={estocaLogo}
          alt="Nome 'estoca' escrito na cor azul, e com hífens nos lados horizontais"
        />
      </a>

      <S.NavArea>
        <Email />
        <SearchOrder openSearch={openSearch} setOpenSearch={setOpenSearch} />
        <Store setOpenStoreModal={setOpenStoreModal} />
        <Profile />
      </S.NavArea>
    </S.Wrapper>
  );
};
