import { useRef, useState } from 'react';

import { useOnClickOutside } from '../../../hooks/ModalHooks';
import { type ProfileMenuItem } from '../Profile';

import * as S from './styles';

interface DropdownHeaderProps {
  items: ProfileMenuItem[];
  width?: number;
}

export const DropdownHeader = ({ items, width }: DropdownHeaderProps) => {
  const wrapperRef = useRef();
  const [open, setOpen] = useState(false);

  const handleItemRedirect = (item: ProfileMenuItem) => {
    setOpen(false);

    if (item?.isExternalLink) {
      window.open(item.href, '_blank');
      return;
    }
    window.location.href = item.href;
  };

  useOnClickOutside(wrapperRef, () => setOpen(false));

  return (
    <div ref={wrapperRef}>
      <S.StyledDropdownHeaderIcon onClick={() => setOpen(!open)} />
      <S.StyledDropdownHeader open={open} css={{ width: width || 140 }}>
        {items.map(item => {
          return (
            <S.StyledDropdownHeaderItem
              key={item.name}
              onClick={() => handleItemRedirect(item)}
            >
              {item.name}
            </S.StyledDropdownHeaderItem>
          );
        })}
      </S.StyledDropdownHeader>
    </div>
  );
};
