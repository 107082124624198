import { Modal } from '@Estoca/ui';
import {
  useState,
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
} from 'react';

import * as S from './styles';

type SearchOptionValue =
  | 'order_id'
  | 'external_id'
  | 'customer_name'
  | 'invoice_number';

type SearchOption = {
  label: string;
  value: SearchOptionValue;
};

const searchedOrderOptions: SearchOption[] = [
  {
    label: 'ID da Estoca',
    value: 'order_id',
  },
  {
    label: 'ID do ERP',
    value: 'external_id',
  },
  {
    label: 'Nome do Cliente',
    value: 'customer_name',
  },
  {
    label: 'Número da NF',
    value: 'invoice_number',
  },
];

interface SearchOrderModalProps {
  openedModal: boolean;
  setOpenedModal: Dispatch<SetStateAction<boolean>>;
}

export const SearchOrderModal = ({
  openedModal,
  setOpenedModal,
}: SearchOrderModalProps) => {
  const [searchedOrder, setSearchedOrder] = useState<string>('');
  const [orderOption, setOrderOption] = useState<SearchOptionValue | ''>('');

  const hasValidValues = !!searchedOrder && !!orderOption;

  const handleChangeOrder = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => setSearchedOrder(value);

  const handleSelectOrderOption = ({
    target: { value },
  }: ChangeEvent<HTMLSelectElement>) =>
    setOrderOption(value as SearchOptionValue);

  const handleCloseModal = () => {
    setOpenedModal(state => !state);
    setSearchedOrder('');
    setOrderOption('');
  };

  return (
    <Modal
      heading="Procurar pedido"
      open={openedModal}
      onClose={handleCloseModal}
      actionButton={
        <S.SearchOrderTrigger
          onBlur={handleCloseModal}
          href={
            hasValidValues
              ? `/outbound?${orderOption}=${searchedOrder}`
              : undefined
          }
          target="_blank"
          rel="noopener noreferrer"
          disabled={!hasValidValues}
        >
          Pesquisar
        </S.SearchOrderTrigger>
      }
    >
      <S.Wrapper>
        <S.Input
          autoFocus
          type="text"
          placeholder="Insira o nome do pedido"
          onChange={handleChangeOrder}
        />

        <S.OrderOptionSelect
          value={orderOption}
          onChange={handleSelectOrderOption}
        >
          <S.SearchOrderOption selected hidden disabled value="">
            Selecione uma opção para pesquisa
          </S.SearchOrderOption>

          {searchedOrderOptions.map(option => (
            <S.SearchOrderOption key={option.value} value={option.value}>
              {option.label}
            </S.SearchOrderOption>
          ))}
        </S.OrderOptionSelect>
      </S.Wrapper>
    </Modal>
  );
};
