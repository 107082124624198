import { Navbar as MyNavbar } from '@Estoca/ui';
import { useState, type Dispatch, type SetStateAction } from 'react';

import { MobileBurger } from './MobileBurger';
import { MobileNavArea } from './MobileNavArea';

interface NavbarProps {
  openSearch: boolean;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  setOpenStoreModal: Dispatch<SetStateAction<boolean>>;
}

export const Navbar = ({
  openSearch,
  setOpenSearch,
  setOpenStoreModal,
}: NavbarProps) => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  return (
    <>
      <MobileBurger isOpen={mobileMenuOpened} setIsOpen={setMobileMenuOpened} />
      <MyNavbar
        isMobile={mobileMenuOpened}
        mobileNavArea={
          <MobileNavArea
            openSearch={openSearch}
            setOpenSearch={setOpenSearch}
            setOpenStoreModal={setOpenStoreModal}
          />
        }
      />
    </>
  );
};
