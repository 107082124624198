import { styled } from '@Estoca/ui';

import dropdownIcon from '../../../public/dropdownIcon.svg';

export const StyledDropdownHeader = styled('ul', {
  position: 'absolute',
  flexDirection: 'column',
  top: '60px',
  margin: '0px',
  backgroundColor: 'white',
  border: '1px solid rgb(221, 221, 221)',
  padding: '0px',
  height: 'fit-content',
  maxHeight: '330px',
  overflow: 'auto',
  zIndex: '9',
  variants: {
    open: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },
});

export const StyledDropdownHeaderItem = styled('li', {
  textDecoration: 'none',
  width: '100%',
  borderBottom: '1px solid rgb(237, 241, 247)',
  textAlign: 'left',
  padding: '10px 20px',
  cursor: 'pointer',
});

export const StyledDropdownHeaderIcon = styled('div', {
  backgroundImage: `url(${dropdownIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '20px',
  height: '20px',
  cursor: 'pointer',
});
