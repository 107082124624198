import { useState } from 'react';

import { Header } from './components/Header';
import { SearchOrderModal } from './components/SearchOrderModal';
import { SelectStoreModal } from './components/SelectStoreModal';
import {
  ProfileData,
  ProfileDataContextProvider,
} from './context/ProfileDataContext';
import { Routes } from './routes';

export interface CoreAppProps {
  profileData: ProfileData | null;
}

export const CoreApp = ({ profileData }: CoreAppProps) => {
  const [searchOrderModalOpened, setSearchOrderModalOpened] = useState(false);
  const [searchStoreModalOpened, setSearchStoreModalOpened] = useState(false);

  return (
    <ProfileDataContextProvider profileData={profileData}>
      <Header
        searchOrderModalOpened={searchOrderModalOpened}
        setSearchOrderModalOpened={setSearchOrderModalOpened}
        setSearchStoreModalOpened={setSearchStoreModalOpened}
      />

      <SearchOrderModal
        openedModal={searchOrderModalOpened}
        setOpenedModal={setSearchOrderModalOpened}
      />
      <SelectStoreModal
        openedModal={searchStoreModalOpened}
        setOpenedModal={setSearchStoreModalOpened}
      />
      <Routes />
    </ProfileDataContextProvider>
  );
};
