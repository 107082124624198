import { globalCss } from '@Estoca/ui';

export const GlobalStyles = globalCss({
  ':root': {
    fontSynthesis: 'none',
    textRendering: 'optimizeLegibility',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    WebkitTextSizeAdjust: '100%',
    WebkitTapHighlightColor: 'transparent',
  },
  '*': {
    margin: '0',
    padding: '0',
    boxSizing: 'border-box !important',
  },
  body: {
    fontFamily:
      "'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
    backgroundColor: '#F9F9F9',
  },
  'body > div[id*=single-spa-application]:not([id*="@Estoca/core"])': {
    height: '100vh',
  },
  button: {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      filter: 'brightness(0.95)',
    },
    '&:disabled, &[disabled]': {
      cursor: 'not-allowed',
    },
  },
  a: {
    border: 'none',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      filter: 'brightness(0.95)',
    },
    '&:disabled, &[disabled]': {
      cursor: 'not-allowed',
    },
  },
  '#background-cover': {
    width: '100vw',
    height: '100vh',
    backgroundColor: '$primaryDark',
    position: 'fixed',
    zIndex: '999',
    display: 'block',
    top: '0',
  },
  '@media': {
    '(max-width: 595px)': {
      '#background-cover': {
        zIndex: '999999',
      },
      '.mobileScroller': {
        width: 'fit-content',
        display: 'flex',
        paddingBottom: '10px',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
          WebkitAppearance: 'none',
        },
        '&::-webkit-scrollbar:vertical': {
          width: '5px',
        },
        '&::-webkit-scrollbar:horizontal': {
          height: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#8F9BB3',
          borderRadius: '0px',
          border: '1px solid #ffffff',
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
          backgroundColor: '#EDF1F7',
        },
      },
    },
  },
});
