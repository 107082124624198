/* eslint-disable import/extensions */
import { type Dispatch, type SetStateAction } from 'react';

import { Navbar } from '../MobileMenu/Navbar';
import { GlobalStyles } from '../Styles/global';

import { HeaderInformation } from './HeaderInformations';

interface HeaderProps {
  searchOrderModalOpened: boolean;
  setSearchOrderModalOpened: Dispatch<SetStateAction<boolean>>;
  setSearchStoreModalOpened: Dispatch<SetStateAction<boolean>>;
}

export const Header = ({
  searchOrderModalOpened,
  setSearchOrderModalOpened,
  setSearchStoreModalOpened,
}: HeaderProps) => {
  return (
    <>
      <GlobalStyles />
      <HeaderInformation
        openSearch={searchOrderModalOpened}
        setOpenSearch={setSearchOrderModalOpened}
        setOpenStoreModal={setSearchStoreModalOpened}
      />
      <Navbar
        openSearch={searchOrderModalOpened}
        setOpenSearch={setSearchOrderModalOpened}
        setOpenStoreModal={setSearchStoreModalOpened}
      />
    </>
  );
};
