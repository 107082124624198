import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useContextSelector } from 'use-context-selector';

import { ProfileDataContext } from '../../../context/ProfileDataContext';
import { StyledDropdownHeaderIcon } from '../DropdownHeader/styles';
import * as S from './styles';

interface StoreProps {
  setOpenStoreModal: Dispatch<SetStateAction<boolean>>;
}

export const Store = ({ setOpenStoreModal }: StoreProps) => {
  const profileData = useContextSelector(
    ProfileDataContext,
    ctx => ctx.profileData
  );
  const [storeSelected, setStoreSelected] = useState(profileData.storeSelected);

  useEffect(() => {
    setStoreSelected(profileData.storeSelected);
  }, [profileData]);

  return (
    <S.Wrapper onClick={() => setOpenStoreModal(state => !state)}>
      {profileData.stores.length && <StyledDropdownHeaderIcon />}
      <span>{storeSelected && storeSelected.name}</span>
    </S.Wrapper>
  );
};
