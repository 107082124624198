import { Modal, SpinnerLoading } from '@Estoca/ui';
import axios from 'axios';
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useContextSelector } from 'use-context-selector';

import { type Status } from '../../@types/status';
import {
  ProfileDataContext,
  type Store,
} from '../../context/ProfileDataContext';
import { Cookie } from '../../helpers/cookie';
import { useCustomEvents } from '../../hooks/use-custom-events';
import { useDebounce } from '../../hooks/use-debounce';
import { ERROR_MESSAGES } from '../../utils/constants';
import * as S from './styles';

type StoresData = {
  page: number;
  per_page: number;
  total: number;
  data: Store[];
};

const apiBaseUrl = process.env.REACT_APP_API_INTERNAL_URL;

interface StoreModalProps {
  openedModal: boolean;
  setOpenedModal: Dispatch<SetStateAction<boolean>>;
}

export const SelectStoreModal = ({
  openedModal,
  setOpenedModal,
}: StoreModalProps) => {
  const { publishCustomEvent } = useCustomEvents();
  const profileData = useContextSelector(
    ProfileDataContext,
    ctx => ctx.profileData
  );
  const setProfileData = useContextSelector(
    ProfileDataContext,
    ctx => ctx.setProfileData
  );

  const defaultStoresData = profileData?.stores || [];
  const [storesData, setStoresData] = useState<Store[]>(defaultStoresData);

  const [status, setStatus] = useState<Status>('idle');
  const [searchedStoreName, setSearchedStoreName] = useState<string>('');
  const searchedStoreNameDebounced = useDebounce<string>(
    searchedStoreName,
    500
  );

  const resetSearchedStores = () => {
    setSearchedStoreName('');
    setStoresData(defaultStoresData);
  };

  const handleCloseModal = () => {
    setOpenedModal(state => !state);
    resetSearchedStores();
  };

  const handleSaveSelectedStore = (selectedStore: Store) => {
    Cookie.set('storeSelected', JSON.stringify(selectedStore), 7);
    Cookie.set('storeSelectedId', selectedStore.id, 7);
    publishCustomEvent('changedStore');
    setProfileData(state => ({ ...state, storeSelected: selectedStore }));
    handleCloseModal();
  };

  const handleChangeStoresData = (storeName: string) => {
    if (!storeName) {
      resetSearchedStores();
      return;
    }
    setSearchedStoreName(storeName);
  };

  const getSearchedStore = async () => {
    try {
      setStatus('loading');

      const params = {
        name: searchedStoreName,
        page: 1,
        per_page: 100,
      };

      const { data } = await axios.get<StoresData>(
        `${apiBaseUrl}/profile/store`,
        { params }
      );

      setStoresData(data.data);
      setStatus('success');
    } catch {
      setStatus('error');
    }
  };

  useEffect(() => {
    if (searchedStoreName) getSearchedStore();
  }, [searchedStoreNameDebounced]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      heading="Selecione uma loja"
      open={openedModal}
      onClose={handleCloseModal}
    >
      <S.Wrapper>
        <S.Input
          autoFocus
          type="text"
          placeholder="Insira o nome da loja"
          value={searchedStoreName}
          onChange={({ target: { value } }) => handleChangeStoresData(value)}
        />

        <S.ListingWrapper>
          <S.SelectedStore>
            <strong>Selecionado:</strong> {profileData?.storeSelected.name}
          </S.SelectedStore>

          {status === 'loading' ? (
            <S.LoadingWrapper>
              <SpinnerLoading size={22} />
            </S.LoadingWrapper>
          ) : (
            storesData.map(store => (
              <S.Store
                key={store.id}
                onClick={() => handleSaveSelectedStore(store)}
                isSelectedStore={store.name === profileData?.storeSelected.name}
              >
                {store.name}
              </S.Store>
            ))
          )}

          {!storesData.length && (
            <S.NotFoundStores>{ERROR_MESSAGES.storesSearch}</S.NotFoundStores>
          )}
        </S.ListingWrapper>
      </S.Wrapper>
    </Modal>
  );
};
