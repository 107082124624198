import { type Dispatch, type SetStateAction } from 'react';

import iconSearch from '../../../public/searchIcon.svg';
import * as S from './styles';

interface SearchOrderProps {
  openSearch: boolean;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
}

export const SearchOrder = ({
  openSearch,
  setOpenSearch,
}: SearchOrderProps) => {
  return (
    <S.Wrapper onClick={() => setOpenSearch(!openSearch)}>
      <img src={iconSearch} alt="icon-Search" />
    </S.Wrapper>
  );
};
