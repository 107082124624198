import { styled } from '@Estoca/ui';

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  gap: '20px',
});

export const SearchOrderTrigger = styled('a', {
  width: '110px',
  minHeight: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '12px 18px',

  color: '$white',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$bold',
  fontSize: '14px',
  lineHeight: '16px',

  textAlign: 'center',
  textDecoration: 'none',

  border: 'none',
  cursor: 'pointer',
  borderRadius: '$md',

  backgroundColor: '$primary',

  transition: 'brightness .2s ease-in-out',

  '&:hover': {
    filter: 'brightness(0.95)',
  },

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
  },
});

export const Input = styled('input', {
  width: '100%',
  height: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  padding: '8px 16px',

  backgroundColor: '#F7F9FC',

  border: '1px solid #E4E9F2',
  borderRadius: '$md',

  color: '#777',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$semiBold',
  fontSize: '15px',
  lineHeight: '24px',
});

export const OrderOptionSelect = styled('select', {
  width: '100%',
  height: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  padding: '8px 16px',

  backgroundColor: '#F7F9FC',

  border: '1px solid #E4E9F2',
  borderRadius: '$md',

  color: '#777',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$semiBold',
  fontSize: '15px',
  lineHeight: '24px',
});

export const SearchOrderOption = styled('option', {
  minWidth: '117px',
  height: '44px',

  border: 'none',
  cursor: 'pointer',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '12px 16px',

  backgroundColor: '$white',

  color: '$black',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$regular',
  fontSize: '14px',
  lineHeight: '20px',

  textDecoration: 'none',

  transition: 'all .2s ease-in-out',

  '&:hover': {
    filter: 'brightness(0.95)',
  },

  '&:disabled, &[disabled]': {
    cursor: 'not-allowed',
  },

  '&:not(:last-child)': {
    borderBottom: '1.6px solid #E5EBF5',
  },

  '&:first-child': {
    borderRadius: '$sm $sm 0 0',
  },

  '&:last-child': {
    borderRadius: '0 0 $sm $sm',
  },

  '@medium': {
    minWidth: '230px',
    justifyContent: 'flex-start',
  },
});
