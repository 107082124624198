import { styled } from '@Estoca/ui';

export const Wrapper = styled('header', {
  zIndex: 99,
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
  color: '#212529',
  overflow: 'visible',
  position: 'fixed',
  top: 0,
  left: 0,

  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 24px',
  gap: '99px',
  height: '60px',

  backgroundColor: '$white',

  '@small': {
    img: {
      marginLeft: '50px',
      borderLeft: '1px solid #ECECEC',
      paddingLeft: '16px',
    },
  },
});

export const Logo = styled('img', {
  width: 'fit-content',
  height: '25px',

  objectFit: 'contain',
  objectPosition: 'center',

  '@medium': {
    marginLeft: '50px',
    borderLeft: '1px solid #ECECEC',
    paddingLeft: '16px',
  },
});

export const NavArea = styled('div', {
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1200px',
  padding: '10px 0px',

  '& > div': {
    borderRight: '1px solid #d3d3d3',
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    padding: '20px',
    height: '14px',
  },

  '@small': {
    display: 'none',
  },
});
