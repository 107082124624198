import { styled } from '@Estoca/ui';

export const StyledBurger = styled('button', {
  position: 'fixed',
  top: '15px',
  left: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  width: '2rem',
  height: '2rem',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: '0',
  zIndex: '99',

  '&:focus': {
    outline: 'none',
  },

  span: {
    width: '28px',
    height: '4px',
    background: '#8f9bb3',
    borderRadius: '10px',
    transition: 'all 0.3s linear',
    position: 'relative',
    transformOrigin: '1px',
  },

  variants: {
    open: {
      true: {
        '& > span:first-child': {
          transform: 'rotate(45deg)',
        },
        '& > span:nth-child(2)': {
          opacity: '0',
          transform: 'translateX(20px)',
        },
        '& > span:nth-child(3)': {
          transform: 'rotate(-45deg)',
        },
      },
      false: {
        '& > span:first-child': {
          transform: 'rotate(0)',
        },
        '& > span:nth-child(2)': {
          opacity: '1',
          transform: 'translateX(0)',
        },
        '& > span:nth-child(3)': {
          transform: 'rotate(0)',
        },
      },
    },
  },

  '@media only screen and (min-width: 769px)': {
    display: 'none',
  },
});
