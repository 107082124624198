import { styled } from '@Estoca/ui';

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  gap: '20px',
  paddingRight: '20px',
});

export const Input = styled('input', {
  width: '100%',
  height: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  padding: '8px 16px',

  backgroundColor: '#F7F9FC',

  border: '1px solid #E4E9F2',
  borderRadius: '$md',

  color: '#777',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$semiBold',
  fontSize: '15px',
  lineHeight: '24px',
});

export const ListingWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const SelectedStore = styled('p', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  width: '100%',
  height: '57px',

  padding: '20px',

  borderBottom: '1px solid #E4E9F2',

  color: '#black',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$regular',
  fontSize: '16px',
  lineHeight: '25px',

  strong: {
    marginRight: '5px',
  },
});

export const LoadingWrapper = styled('div', {
  margin: '20px auto 0',
});

export const Store = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  width: '100%',
  height: '65px',

  padding: '20px',

  border: 'none',
  cursor: 'pointer',
  backgroundColor: 'transparent',

  transition: 'all .2s ease-in-out',

  '&:hover, &:active &:focus': {
    backgroundColor: '#F7F9FC',
  },

  '&:disabled, &[disabled]': {
    cursor: 'not-allowed',
  },

  '&:not(:last-child)': {
    borderBottom: '1px solid #E4E9F2',
  },

  color: '#black',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$semiBold',
  fontSize: '16px',
  lineHeight: '25px',

  variants: {
    isSelectedStore: {
      true: {
        backgroundColor: '#F7F9FC',
      },
    },
  },
});

export const SelectStoreButton = styled('button', {
  width: '110px',
  minHeight: '40px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: '12px 18px',

  color: '$white',

  borderRadius: '$md',
  backgroundColor: '$primary',

  fontFamily: '$base',
  fontStyle: '$normal',
  fontWeight: '$bold',
  fontSize: '14px',
  lineHeight: '16px',

  textAlign: 'center',
});

export const NotFoundStores = styled('p', {
  padding: '20px 20px 0',

  color: '#black',

  fontFamily: '$base',
  fontStyle: '$normal',

  fontWeight: '$semiBold',
  fontSize: '16px',
  lineHeight: '25px',
});
