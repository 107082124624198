const VALID_APP_STATIC_PATHS = [
  '/',
  '/home',
  '/dashboard',

  '/analytics',
  '/analytics/tickets',

  '/inbound',
  '/inbound/new',

  '/outbound',
  '/outbound/new',
  '/outbound/out-of-stock',
  '/outbound/deleted',
  '/outbound/errors',

  '/inventory/performance',
  '/inventory/control',
];

const VALID_APP_DYNAMIC_PATHS = [
  // inbound routes
  '/review',
  '/continue',
  '/send-invoice',

  '/outbound/order/timeline/',
];

export const APP_ROUTE_PATHS = {
  staticPaths: VALID_APP_STATIC_PATHS,
  dynamicPaths: VALID_APP_DYNAMIC_PATHS,
};

export const ERROR_MESSAGES = {
  storesSearch: 'Não foi possível encontrar dados para a sua busca de loja.',
} as const;
