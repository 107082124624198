import { styled } from '@Estoca/ui';

export const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '@media (max-width: 595px)': {
    width: '12%',
  },
});
