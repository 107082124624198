import { NotFound } from '@Estoca/ui';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

export const NotFoundRoute = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
