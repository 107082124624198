import { styled } from '@Estoca/ui';

export const StyledEmail = styled('div', {
  height: '14px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '14px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  textTransform: 'uppercase',
  border: '1px solid #d3d3d3',
  width: '100%',
  borderTopColor: 'transparent',
  borderBottomColor: 'transparent',
  padding: '20px',
  a: {
    textDecoration: 'none',
    color: '#8F9BB3',
    display: 'flex',
  },
  span: {
    marginLeft: '10px',

    '@medium': {
      display: 'none',
    },
  },
  '@small': {
    width: '12%',
  },
});
