import { styled } from '@Estoca/ui';

export const Wrapper = styled('div', {
  fontStyle: 'normal',
  fontWeight: '$semiBold',
  fontSize: '12px',
  lineHeight: '20px',
  borderRight: 'none',

  display: 'flex',
  alignItems: 'center',

  color: '#8f9bb3',

  span: {
    width: 'max-content',
    cursor: 'pointer',
  },

  '@media (max-width: 595px)': {
    width: '38%',
  },
});
