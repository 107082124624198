import React from 'react';

import iconEmail from '../../../public/emailIcon.svg';

import { StyledEmail } from './styles';

const Email: React.FunctionComponent<{ mobile?: boolean }> = ({ mobile }) => {
  return (
    <StyledEmail>
      <a href="https://suporte.estoca.com.br/hc/pt-br">
        <img src={iconEmail} alt="icon-email" />
        {!mobile && <span>Central de Atendimento</span>}
      </a>
    </StyledEmail>
  );
};

export default Email;
