import { type Dispatch, type SetStateAction } from 'react';

import Email from '../../Header/Email/Email';
import { Profile } from '../../Header/Profile';
import { SearchOrder } from '../../Header/SearchOrder';
import { Store } from '../../Header/Store';
import * as S from './styles';

interface MobileNavAreaProps {
  openSearch: boolean;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  setOpenStoreModal: Dispatch<SetStateAction<boolean>>;
}

export const MobileNavArea = ({
  openSearch,
  setOpenSearch,
  setOpenStoreModal,
}: MobileNavAreaProps) => {
  return (
    <S.Wrapper>
      <Email mobile />
      <SearchOrder openSearch={openSearch} setOpenSearch={setOpenSearch} />
      <Store setOpenStoreModal={setOpenStoreModal} />
      <Profile />
    </S.Wrapper>
  );
};
