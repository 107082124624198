import { APP_ROUTE_PATHS } from '../utils/constants';

import { NotFoundRoute } from './NotFound';

export const Routes = () => {
  const isValidStaticPath = APP_ROUTE_PATHS.staticPaths.some(
    staticPath => location.pathname === staticPath
  );

  const isValidDynamicPath = APP_ROUTE_PATHS.dynamicPaths.some(dynamicPath =>
    location.pathname.includes(dynamicPath)
  );

  const isValidPath = isValidStaticPath || isValidDynamicPath;

  return !isValidPath ? <NotFoundRoute /> : null;
};
